<template>
  <Modal :value="visible"
         :title="title"
         width="740"
         footer-hide
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="equipment">
      <ul class="equipment-cont"
          v-if="item&&item.gridBindList">
        <li>
          <Icon custom="i-icon icon-jingshi"
                size="18"
                color="#F68040" />
          <span>{{item.gridBindList.length > 0 ? "已绑围栏" : "未绑定围栏"}}</span>
        </li>
        <li class="binding"
            v-for="(item,index) in item.gridBindList"
            :key="index">
          <span>{{item.gridName}}</span>
          <Icon custom="i-icon icon-guanbi"
                size="16"
                color="black"
                class="binding-icon"
                @click.stop="onClickUnbind(item)" />
        </li>
      </ul>
      <div class="equipment-filtrate">
        <MyTreeSelect :value="departmentName"
                      placeholder="所属机构"
                      clearable
                      :data="departmentTreeArr"
                      search
                      class="m-r-10"
                      style="width:160px"
                      @onSelectChange="onChangeDepartment">
        </MyTreeSelect>
        <Input v-model="name"
               placeholder="网格名称"
               @on-enter="getList"
               clearable
               class="m-r-5"
               style="width:160px"></Input>
        <!-- <Select v-model="status"
                placeholder="是否绑定"
                @on-change="getList">
          <Option v-for="item in statusArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select> -->
      </div>
      <Table ref="table"
             :columns="columns"
             :data="data"
             height="320"
             border
             stripe
             :loading="tableLoading"
             @on-selection-change="onChangeSelect"
             @on-row-click="onClickBind">
      </Table>
      <Page class="m-t-10 m-l-10"
            :total="totalPage"
            :current="pageNum"
            :page-size="pageSize"
            show-total
            show-sizer
            show-elevator
            @on-change="onPageChange"
            @on-page-size-change="onPageSizeChange" />
      <!-- <Button class="m-t-10 m-l-10"
              :disabled="selectArr.length===0"
              :loading="loading"
              @click.stop="onClickBind">绑定</Button> -->
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { debounce } from '@/utils/debounceAndThrottle';
export default {
  name: 'equipmentBindingModal',
  components: { MyTreeSelect },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '绑定对象'
    },
    item: null,
    objectType: String,
  },
  data () {
    return {
      type: '',
      model: '',
      modelArr: [],
      name: '',
      status: 1,
      departmentName: '',
      departmentId: '',
      statusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未绑定'
        },
        {
          id: 2,
          name: '已绑定'
        },
      ],
      columns: [
        // {
        //   type: 'selection',
        //   width: 45,
        //   align: 'center'
        // },
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '网格名称',
          key: 'name',
          tooltip: true,
          minWidth: 200,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          width: 70,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
      ],
      data: [],
      pageNum: 1,
      pageSize: 20,
      totalPage: 0,
      tableLoading: false,
      selectArr: [],
      selectArrData: [],
      loading: false,
      // 绑定设备
      bindConfig: {
        title: '是否确认绑定',
        content: '正确绑定后，该设备将上传数据！'
      },
      // 解绑设备
      unbindConfig: {
        title: '是否确认此操作',
        content: '解绑后，该对象不再发送此类数据'
      },
      // 绑定并替换
      bindAndReplaceConfig: {
        title: '是否确认绑定',
        content: '解绑该设备原有绑定的设施，并把设备绑定在新设施上！'
      },
    }
  },
  watch: {
    visible (newVal) {
      newVal && this.getList()
    },
    name (newVal) {
      this.onSearchName()
    },
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    typeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getEquipmentTypeAllList)
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateEquipmentTypeAllList',
      'updateDepartmentTreeList',
    ]),
    init () {
      this.updateEquipmentTypeAllList()
      this.updateDepartmentTreeList()
    },
    onSearchName: debounce(function () {
      this.pageNum = 1
      this.selectArr = []
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.name,
        itemIdList: [],
        depid: this.departmentId,
      }
      this.tableLoading = true
      this.$http.getEnclosureList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.gridMXVOList
        }
      }).catch(err => {
        this.tableLoading = false
      })
    }),
    onChangeType (value) {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      this.$http.getEquipmentModelAllList({ equiptypeid: value }).then(res => {
        if (res.code === 200) {
          this.modelArr = arr.concat(res.result)
        }
      })
      this.model = ''
      this.getList()
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
      this.onClickSearch()
    },
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.name,
        itemIdList: [],
        depid: this.departmentId,
      }
      this.tableLoading = true
      this.$http.getEnclosureList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.gridMXVOList
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击解绑
    onClickUnbind (item) {
      let params = {
        gridIdList: [item.gridId],
        itemIdList: [this.item.id],
      }
      this.$Modal.confirm({
        title: '解绑',
        content: '解绑后，不再以此电子围栏作为考勤依据',
        onOk: () => {
          this.loading = true
          this.$http.unboundEnclosurePersonOrCar(params).then((res) => {
            if (res.code == 200) {
              this.loading = false
              this.getList()
              this.$emit('onClickConfirm')
              let index = this.item.gridBindList.findIndex(items => items.gridId === params.gridId)
              this.item.gridBindList.splice(index, 1)
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    // 点击绑定
    onClickBind (row) {
      // let check = {
      //   gridIdList: this.selectArr,
      //   itemIdList: [this.item.id],
      // }
      // this.$http.checkEnclosurePersonOrCar(check).then((res) => {
      //   if (res.code === 200) {
      // if (res.result == 1) {
      // 绑定
      let params = {
        gridId: row.id,
        // name: this.item.name,
        // gridType: 1,
        itemId: this.item.id,
        carAndEmpType: this.objectType,
      }
      this.$Modal.confirm({
        title: '绑定',
        content: '确认绑定后，将根据次电子围栏进行考勤管理，次日生效',
        onOk: () => {
          this.loading = true
          this.$http.boundEnclosurePersonOrCar(params).then((res) => {
            if (res.code == 200) {
              this.getList()
              this.loading = false
              this.$emit('onClickConfirm')
              row.gridName = row.name
              row.gridId = row.id
              this.item.gridBindList = [row]
            } else {
              this.loading = false
            }
          }).catch((err) => {
            this.loading = false
          });
        }
      })
      //     } else {
      //       this.$Modal.confirm({
      //         title: '警告',
      //         content: '已绑定电子围栏请解绑后重试',
      //         onOk: () => {
      //         }
      //       })
      //     }
      //   }
      // })
    },
    // tab多选
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectArrData = selection
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.selectArr = []
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.selectArr = []
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.selectArr = []
      this.getList()
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
      this.type = ''
      this.model = ''
      this.modelArr = []
      this.name = ''
      this.status = 1
      this.departmentId = ''
      this.departmentName = ''
      this.data = []
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment {
  .equipment-cont {
    background: #f2f2f2;
    padding: 10px;
    margin: 10px 0;
    // height: 44px;
    overflow-y: scroll;
    font-size: 12px;
    display: flex;
    align-items: center;
    li {
      width: 140px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: 34px;
      font-size: 12px;
      box-sizing: border-box;
      padding: 5px;
      color: #353639;
      span {
        display: inline-block;
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    li:first-child {
      width: 96px;
    }
    .binding {
      padding: 4px !important;
      background-color: #fff;
      border: 1px solid #dddddd;
      margin: 11px 5px 11px 0;
      height: 22px;
      .binding-icon {
        cursor: pointer;
      }
    }
  }
  .equipment-filtrate {
    display: flex;
    // justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
