<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="section-title">
        <span>基础信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>车牌号：</p>
          <Input v-model="plateNumber"
                 placeholder="车牌号"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departmentTree"
                        placeholder="所属机构"
                        clearable
                        :data="departmentTreeArr"
                        search
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>作业类型：</p>
          <Cascader :data="workTypeArr"
                    v-model="workType"
                    class="m-r-10"
                    style="width:160px"
                    change-on-select
                    placeholder="作业类型："></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>车辆所属权：</p>
          <Select v-model="ownership"
                  placeholder="车辆所属权">
            <Option :value="item.id"
                    v-for="item in ownershipArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>车辆型号：</p>
          <Cascader :data="carTypeModelArr"
                    v-model="carTypeModel"
                    change-on-select
                    style="width:100%;"></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">油卡编号：</p>
          <Input v-model="oilCard"
                 placeholder="油卡编号"
                 maxlength="30"
                 clearable
                 v-intLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">发动机号：</p>
          <Input v-model="engine"
                 placeholder="发动机号"
                 maxlength="30"
                 clearable=""
                 v-charAndNumberLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">车架号：</p>
          <Input v-model="carframe"
                 placeholder="车架号"
                 maxlength="30"
                 clearable
                 v-charAndNumberLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">购入日期：</p>
          <DatePicker :value="time"
                      type="date"
                      placeholder="购入日期"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">购入金额：</p>
          <Input v-model="money"
                 placeholder="购入金额"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">元</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">初始里程：</p>
          <Input v-model="mileage"
                 placeholder="初始里程"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">km</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">监测扫盘：</p>
          <i-switch v-model="sweepPlate"
                    disabled />
        </li>
        <li class="form-ul-li half">
          <p class="li-title">监测喷头：</p>
          <i-switch v-model="sprayer"
                    disabled />
        </li>
        <li class="form-ul-li half">
          <p class="li-title">转运类型：</p>
          <Input v-model="transferType"
                 placeholder="转运类型"
                 disabled></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">rfId卡号：</p>
          <Input v-model="rfId"
                 placeholder="rfId卡号"
                 maxlength="30"
                 clearable
                 v-intLimit></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">车辆图片：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="carPicture">
              <li>
                <el-image :src="carPicture"
                          fit="cover"
                          :preview-src-list="[carPicture]"
                          class="w-full h-full"
                          v-show="carPicture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="carPicture" />
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5"
                      v-show="!carPicture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过5张，最大不能超过1M；
          </div>
        </li>

      </ul>
      <div class="section-title">
        <span>车辆信息</span>
      </div>

      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">驱动方式：</p>
          <Input v-model="drivemodel"
                 disabled
                 placeholder="驱动方式"
                 class="input-right-5"></Input>
          <!-- <Select v-model="drivemodel"
                  placeholder="驱动方式"
                  disabled>
            <Option :value="item.value"
                    v-for="item in driveArr"
                    :key="item.value">{{item.name}}
            </Option>
          </Select> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title">燃油类型：</p>
          <Input v-model="detail.fuelOil"
                 disabled
                 placeholder="燃油类型"
                 class="input-right-5"></Input>
          <!-- <Select v-model="detail.fuelOil"
                  placeholder="燃油类型"
                  disabled>
            <Option :value="item.value"
                    v-for="item in fuelOilArr"
                    :key="item.value">{{item.name}}
            </Option>
          </Select> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title">油箱容积：</p>
          <Input v-model="detail.fuelTank"
                 disabled
                 placeholder="油箱容积"
                 class="input-right-5"></Input>
          <span class="li-unit">L</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">油箱纠正值：</p>
          <Input v-model="fuelTankCorrectValues"
                 placeholder="油箱纠正值"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">L</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">电池容量：</p>
          <Input v-model="detail.cell"
                 disabled
                 placeholder="电池容量"
                 class="input-right-5"></Input>
          <span class="li-unit">AH</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">厂商：</p>
          <Input v-model="detail.firm"
                 disabled
                 placeholder="厂商"
                 class="input-right-5"></Input>
          <!-- <Select v-model="detail.firm"
                  placeholder="厂商"
                  disabled>
            <Option :value="item.value"
                    v-for="item in firmArr"
                    :key="item.value">{{item.name}}
            </Option>
          </Select> -->
        </li>
        <li class="form-ul-li half">
          <p class="li-title">载重：</p>
          <Input v-model="load"
                 disabled
                 placeholder="载重"
                 class="input-right-5"></Input>
          <span class="li-unit">T</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">车身颜色：</p>
          <Select v-model="carColor"
                  placeholder="车身颜色"
                  clearable>
            <Option :value="item.id"
                    v-for="item in carColorArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">车身净重：</p>
          <Input v-model="detail.suttle"
                 disabled
                 placeholder="车身净重"
                 class="input-right-5"></Input>
          <span class="li-unit">T</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">百公里油耗：</p>
          <Input v-model="detail.fuelConsumption"
                 disabled
                 placeholder="百公里油耗"
                 class="input-right-5"></Input>
          <span class="li-unit">L/100km</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业宽度：</p>
          <Input v-model="detail.workwidth"
                 disabled
                 placeholder="作业宽度"
                 class="input-right-5"></Input>
          <span class="li-unit">m</span>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
      <!-- <div class="section-title">
        <span>车务管理</span>
      </div>

      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">下次年检日期：</p>
          <Input v-model="annualInspection"
                 placeholder="下次年检日期"
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">保险到期日期：</p>
          <Input v-model="insurance"
                 placeholder="保险到期日期"
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">下次保养日期：</p>
          <Input v-model="maintainDate"
                 placeholder="下次保养日期"
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">下次保养里程：</p>
          <Input v-model="maintainMileage"
                 placeholder="下次保养里程"
                 maxlength="30"
                 v-intLimit
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
      </ul> -->

      <div class="section-title">
        <span>行驶证</span>
      </div>

      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title">行驶证编号：</p>
          <Input v-model="drivingLicenseNumber"
                 placeholder="行驶证编号"
                 maxlength="30"
                 clearable
                 v-intLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">发证日期：</p>
          <DatePicker type="date"
                      v-model="drivingLicenseDate"
                      placeholder="发证日期"></DatePicker>
        </li>
        <li class="form-ul-li">
          <p class="li-title">行驶证照：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadLicensemainpicture"
                        :disabled="licensemainpicture">
              <li>
                <el-image :src="licensemainpicture"
                          fit="cover"
                          :preview-src-list="[licensemainpicture]"
                          class="w-full h-full"
                          v-show="licensemainpicture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteLicensemainpicture"
                      v-show="licensemainpicture" />
                <span v-show="!licensemainpicture">(主页)</span>
              </li>
            </UploadFile>
            <UploadFile @uploadSuccess="uploadLicenseotherpicture"
                        :disabled="licenseotherpicture">
              <li>
                <el-image :src="licenseotherpicture"
                          fit="cover"
                          :preview-src-list="[licenseotherpicture]"
                          class="w-full h-full"
                          v-show="licenseotherpicture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeleteLicenseotherpicture"
                      v-show="licenseotherpicture" />
                <span v-show="!licenseotherpicture">(副页)</span>
              </li>
            </UploadFile>
          </ul>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UploadFile from '@/components/common/UploadFile';
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
export default {
  components: {
    MyTreeSelect,
    UploadFile
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '车辆'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      plateNumber: '',
      departmentTree: '',
      departmentTreeId: '',
      workType: [],
      workTypeName: '',
      ownership: '',
      carTypeModel: [],
      oilCard: '',
      engine: '',
      carframe: '',
      time: '',
      money: '',
      mileage: '',
      sweepPlate: false,
      sprayer: false,
      transferType: '',
      rfId: '',
      carPicture: '',
      fuelTankCorrectValues: '',
      load: '',
      carColor: '',
      remark: '',
      annualInspection: '',
      insurance: '',
      maintainDate: '',
      maintainMileage: '',
      drivingLicenseNumber: '',
      drivingLicenseDate: '',
      licensemainpicture: '',
      licenseotherpicture: '',
      drivemodel: '',
      detail: {},
      stateArr: [],
      ownershipArr: [
        {
          id: '自用',
          name: '自用',
        },
        {
          id: '租用',
          name: '租用',
        },
        {
          id: '甲方',
          name: '甲方',
        }
      ],
      // carTypeModelArr: [],
      driveArr: [],
      fuelOilArr: [],
      firmArr: [],
      carColorArr: [{
        id: '白色',
        name: '白色',
      },
      {
        id: '黑色',
        name: '黑色',
      },
      {
        id: '银色',
        name: '银色',
      },
      {
        id: '红色',
        name: '红色',
      },
      {
        id: '蓝色',
        name: '蓝色',
      },
      {
        id: '灰色',
        name: '灰色',
      },
      {
        id: '黄色',
        name: '黄色',
      },
      {
        id: '全部',
        name: '全部',
      },
      ],
      businessArr: [],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    workTypeArr () {
      let arr = []
      arr = this.$store.getters.getCarWorkBigClassAllList.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.itemlist
        item.children.map(subItem => {
          subItem.value = subItem.id
          subItem.label = subItem.name
          return subItem
        })
        return item
      })
      return arr
    },
    carTypeModelArr () {
      let arr = []
      arr = this.$store.getters.getCarTypeAllList.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.models
        item.children.map(subItem => {
          subItem.value = subItem.id
          subItem.label = subItem.name
          return subItem
        })
        return item
      })
      arr = this.$store.getters.getCarTypeEnableAllList.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.models
        item.children.map(subItem => {
          subItem.value = subItem.id
          subItem.label = subItem.name
          return subItem
        })
        return item
      })
      return arr
      
    },
    
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarWorkBigClassAllList',
      'updateCarTypeAllList',
      'updateCarTypeEnableAllList'
    ]),
    init () {
      this.updateDepartmentTreeList()
      // carArchives已调用，防重复调用
      // this.updateCarWorkBigClassAllList()
      this.updateCarTypeAllList()
      this.updateCarTypeEnableAllList()
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getCarDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        this.sweepPlate = this.sweepPlate == 1 ? true : false
        this.sprayer = this.sprayer == 1 ? true : false
        this.workType = [res.workType, res.workTypeItem]
        this.carTypeModel = [res.carType, res.carModel]
      })
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentTreeId = section.id
      this.departmentTree = section.name
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.carPicture = arr[0].url
      }
    },
    onClickDeletePicture () {
      this.carPicture = ''
    },
    // 行驶证主页照片
    uploadLicensemainpicture (arr) {
      if (arr.length > 0) {
        this.licensemainpicture = arr[0].url
      }
    },
    // 删除行驶证主页照片
    onClickDeleteLicensemainpicture () {
      this.licensemainpicture = ''
    },
    // 行驶证副页照片
    uploadLicenseotherpicture (arr) {
      if (arr.length > 0) {
        this.licenseotherpicture = arr[0].url
      }
    },
    // 删除行驶证副页照片
    onClickDeleteLicenseotherpicture () {
      this.licenseotherpicture = ''
    },
    onClickConfirm () {
      if (!this.plateNumber) return this.$Message.info('请输入车牌号');
      if (!this.departmentTree) return this.$Message.info('请选择所属机构');
      if (this.workType.length == 0) return this.$Message.info('请选择作业类型');
      if (!this.ownership) return this.$Message.info('请选择所属权');
      if (this.carTypeModel.length == 0) return this.$Message.info('请选择车辆型号');
      if (this.carTypeModel.length == 1) return this.$Message.info('请添加车辆类型');
      this.loading = true
      let params = {
        plateNumber: this.plateNumber,
        departmentTreeId: this.departmentTreeId,
        workType: this.workType[0] ? this.workType[0] : '',
        workTypeItem: this.workType[1] ? this.workType[1] : '',
        ownership: this.ownership,
        carType: this.carTypeModel[0] ? this.carTypeModel[0] : '',
        carModel: this.carTypeModel[1] ? this.carTypeModel[1] : '',
        oilCard: this.oilCard,
        engine: this.engine,
        carframe: this.carframe,
        time: this.time,
        money: this.money,
        mileage: this.mileage,
        rfId: this.rfId,
        carPicture: this.carPicture,
        fuelTankCorrectValues: this.fuelTankCorrectValues,
        carColor: this.carColor,
        remark: this.remark,
        // annualInspection: this.annualInspection,
        // insurance: this.insurance,
        // maintainDate: this.maintainDate,
        // maintainMileage: this.maintainMileage,
        drivingLicenseNumber: this.drivingLicenseNumber,
        drivingLicenseDate: this.drivingLicenseDate,
        licensemainpicture: this.licensemainpicture,
        licenseotherpicture: this.licenseotherpicture,
      }
      if (this.dataId) params.ids = this.dataId
      this.$store.dispatch('createOrEditCar', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新车辆树下拉框
        this.$store.dispatch('updateCarTreeList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.plateNumber = ''
      this.departmentTree = ''
      this.departmentTreeId = ''
      this.workType = []
      this.ownership = ''
      this.carTypeModel = []
      this.oilCard = ''
      this.engine = ''
      this.carframe = ''
      this.time = ''
      this.money = ''
      this.mileage = ''
      this.sweepPlate = false
      this.sprayer = false
      this.transferType = ''
      this.rfId = ''
      this.carPicture = ''
      this.drivemodel = ''
      this.detail = {}
      this.fuelTankCorrectValues = ''
      this.load = ''
      this.carColor = ''
      this.remark = ''

      this.annualInspection = ''
      this.insurance = ''
      this.maintainDate = ''
      this.maintainMileage = ''

      this.drivingLicenseNumber = ''
      this.drivingLicenseDate = ''
      this.licensemainpicture = ''
      this.licenseotherpicture = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.file-img {
  li {
    width: 170px;
    span {
      font-size: 16px;
      font-weight: 900;
      color: #cccccccc;
    }
  }
}
</style>
