<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>车辆型号：</p>
          <Input v-model="modelNum"
                 placeholder="车辆型号"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>车辆类型：</p>
          <Select v-model="type"
                  placeholder="车辆类型">
            <Option :value="item.id"
                    v-for="item in typeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">厂商：</p>
          <Select v-model="firm"
                  placeholder="厂商"
                  clearable>
            <Option :value="item.id"
                    v-for="item in firmArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>驱动方式：</p>
          <Select v-model="actuate"
                  placeholder="驱动方式">
            <Option :value="item.id"
                    v-for="item in actuateArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">燃油类型：</p>
          <Select v-model="fuelOil"
                  placeholder="燃油类型"
                  clearable>
            <Option :value="item.id"
                    v-for="item in fuelOilArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">油箱容积：</p>
          <Input v-model="fuelTank"
                 placeholder="油箱容积"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">L</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">电池容量：</p>
          <Input v-model="cell"
                 placeholder="电池容量"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">AH</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">载重：</p>
          <Input v-model="load"
                 placeholder="载重"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">T</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">车身净重：</p>
          <Input v-model="suttle"
                 placeholder="车身净重"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">T</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">百公里油耗：</p>
          <Input v-model="fuelConsumption"
                 placeholder="百公里油耗"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">L/100km</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">作业宽度：</p>
          <Input v-model="width"
                 placeholder="作业宽度"
                 maxlength="30"
                 v-floatLimit
                 clearable
                 class="input-right-5"></Input>
          <span class="li-unit">m</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '车辆型号'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      modelNum: '',
      type: '',
      firm: '',
      actuate: '',
      fuelOil: '',
      fuelTank: '',
      cell: '',
      load: '',
      suttle: '',
      fuelConsumption: '',
      width: '',
      status: true,
      remark: '',
      // typeArr: [],
      // firmArr: [],
      actuateArr: [{
        id: '燃油',
        name: '燃油',
      },
      {
        id: '电动',
        name: '电动',
      },
      {
        id: '混合动力',
        name: '混合动力',
      },
      ],
      fuelOilArr: [
        {
          name: '92#',
          id: '92#'
        },
        {
          name: '93#',
          id: '93#'
        },
        {
          name: '95#',
          id: '95#'
        },
        {
          name: '97#',
          id: '97#'
        },
        {
          name: '98#',
          id: '98#'
        }
      ],
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'typeArr': 'getCarTypeAllList',
      'firmArr': 'getCarManufacturerAllList'
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarManufacturerAllList',
    ]),
    init () {
      // carArchives已调用，防重复调用
      // this.updateCarTypeAllList()
      // this.updateCarManufacturerAllList()
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getCarModelNumDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
        this.status = this.status == 1 ? true : false
      })
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.modelNum) return this.$Message.info('请输入车辆型号');
      if (!this.type) return this.$Message.info('请选择车辆类型');
      if (!this.actuate) return this.$Message.info('请选择驱动方式');
      this.loading = true
      let params = {
        modelNum: this.modelNum,
        type: this.type,
        firm: this.firm,
        actuate: this.actuate,
        fuelOil: this.fuelOil,
        fuelTank: this.fuelTank,
        cell: this.cell,
        load: this.load,
        suttle: this.suttle,
        fuelConsumption: this.fuelConsumption,
        width: this.width,
        status: this.status ? 1 : 0,
        remark: this.remark,
        operationtypeid: ''
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditCarModelNum', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新车辆类型下拉列表
        this.$store.dispatch('updateCarTypeAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.modelNum = ''
      this.type = ''
      this.firm = ''
      this.actuate = ''
      this.fuelOil = ''
      this.fuelTank = ''
      this.cell = ''
      this.load = ''
      this.suttle = ''
      this.fuelConsumption = ''
      this.width = ''
      this.status = true
      this.remark = ''
      this.operationtypeid = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.file-img {
  li {
    width: 170px;
    span {
      font-size: 16px;
      font-weight: 900;
      color: #cccccccc;
    }
  }
}
</style>
