<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>类型名称：</p>
          <Input v-model="name"
                 placeholder="类型名称"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">转运类型：</p>
          <Select v-model="type"
                  placeholder="转运类型"
                  clearable>
            <Option :value="item.id"
                    v-for="item in typeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>监控图标：</p>
          <Select v-model="icon"
                  placeholder="监控图标"
                  @on-change="onChangeIcon">
            <img :src="getIconUrl"
                 slot="prefix"
                 v-if="icon"
                 style="width:30px;height:30px" />
            <Option :value="item.id"
                    v-for="item in iconArr"
                    :key="item.id">
              <img :src="item.name">
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用状态：</p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="status"></Checkbox>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="icon-div">
            <div class="icon-div-item">
              <div>
                <img :src="onLineIcon"
                     v-show="onLineIcon">
              </div>
              <p class="li-title">在线</p>
            </div>
            <div class="icon-div-item">
              <div>
                <img :src="offLineIcon"
                     v-show="offLineIcon">
              </div>
              <p class="li-title">离线</p>
            </div>
            <div class="icon-div-item">
              <div>
                <img :src="policeIcon"
                     v-show="policeIcon">
              </div>
              <p class="li-title">报警</p>
            </div>
          </div>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">监控扫盘：</p>
          <i-switch v-model="sweepPlate"></i-switch>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">监控喷头：</p>
          <i-switch v-model="sprayer"></i-switch>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '车辆类型'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      type: '',
      icon: '',
      status: true,
      sweepPlate: false,
      sprayer: false,
      remark: '',
      onLineIcon: '',
      offLineIcon: '',
      policeIcon: '',
      typeArr: [
        {
          id: '转入',
          name: '转入',
        }, {
          id: '转出',
          name: '转出',
        }, {
          id: '无',
          name: '无',
        }
      ],
      iconArr: [
        {
          id: 'bgc',
          name: require('../../../../../assets/common/car/bgc_in.png')
        },
        {
          id: 'chuan',
          name: require('../../../../../assets/common/car/chuan_in.png')
        },
        {
          id: 'dgnycc',
          name: require('../../../../../assets/common/car/dgnycc_in.png')
        },
        {
          id: 'gbc',
          name: require('../../../../../assets/common/car/gbc_in.png')
        }, {
          id: 'gcc',
          name: require('../../../../../assets/common/car/gcc_in.png')
        }, {
          id: 'hc',
          name: require('../../../../../assets/common/car/hc_in.png')
        }, {
          id: 'hlqxc',
          name: require('../../../../../assets/common/car/hlqxc_in.png')
        }, {
          id: 'other',
          name: require('../../../../../assets/common/car/other_in.png')
        }, {
          id: 'sdc',
          name: require('../../../../../assets/common/car/sdc_in.png')
        }, {
          id: 'slmtc',
          name: require('../../../../../assets/common/car/slmtc_in.png')
        }, {
          id: 'ssc',
          name: require('../../../../../assets/common/car/ssc_in.png')
        }, {
          id: 'tlj',
          name: require('../../../../../assets/common/car/tlj_in.png')
        }, {
          id: 'xcc',
          name: require('../../../../../assets/common/car/xcc_in.png')
        }, {
          id: 'xfc',
          name: require('../../../../../assets/common/car/xfc_in.png')
        }, {
          id: 'xsc',
          name: require('../../../../../assets/common/car/xsc_in.png')
        }, {
          id: 'ysc',
          name: require('../../../../../assets/common/car/ysc_in.png')
        }, {
          id: 'ztc',
          name: require('../../../../../assets/common/car/ztc_in.png')
        }, {
          id: 'zxc',
          name: require('../../../../../assets/common/car/zxc_in.png')
        }, {
          id: 'zyc',
          name: require('../../../../../assets/common/car/zyc_in.png')
        }
      ],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    getIconUrl () {
      return this.iconArr.find(item => item.id === this.icon).name
    }
  },
  methods: {
    ...mapActions([
      'updateCarTypeAllList'
    ]),
    getDetail () {
      this.$store.dispatch('getCarTypeDetail', this.dataId).then(res => {
        this.name = res.name
        this.operationtypeid = res.operationtypeid
        this.icon = res.icon
        this.type = res.type
        this.sweepPlate = res.sweepPlate == 1 ? true : false
        this.sprayer = res.sprayer == 1 ? true : false
        this.status = res.status == 1 ? true : false
        this.remark = res.remark
        let index = this.iconArr.findIndex(item => {
          return item.id == res.icon
        })
        this.onLineIcon = require('../../../../../assets/common/car/' + this.iconArr[index].id + '_in.png')
        this.offLineIcon = require('../../../../../assets/common/car/' + this.iconArr[index].id + '_offline.png')
        this.policeIcon = require('../../../../../assets/common/car/' + this.iconArr[index].id + '_alert.png')
      })
    },
    // 监控图标更改
    onChangeIcon (value) {
      if (value) {
        this.onLineIcon = require('../../../../../assets/common/car/' + value + '_in.png')
        this.offLineIcon = require('../../../../../assets/common/car/' + value + '_offline.png')
        this.policeIcon = require('../../../../../assets/common/car/' + value + '_alert.png')
      }
    },
    // 确认
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入工种');
      if (!this.icon) return this.$Message.info('请选择监控图标');
      this.loading = true
      let params = {
        name: this.name,
        operationtypeid: '',
        icon: this.icon,
        type: this.type,
        sweepPlate: this.sweepPlate ? 1 : 0,
        sprayer: this.sprayer ? 1 : 0,
        status: this.status ? 1 : 0,
        remark: this.remark,
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditCarType', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        // 更新车辆类型下拉框
        this.$store.dispatch('updateCarTypeAllList', true)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      // !boo && this.$emit('onChange', false)
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.icon = ''
      this.type = ''
      this.sweepPlate = false
      this.sprayer = false
      this.status = true
      this.remark = ''
      this.onLineIcon = ''
      this.offLineIcon = ''
      this.policeIcon = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-div {
  display: flex;
  // text-align: center;
  .icon-div-item {
    div {
      width: 45px;
      height: 45px;
      background: #f0f3f6;
      margin: 0 auto;
      border: 1px solid #b3b0b0;
      border-radius: 3px;
      line-height: 45px;
      // text-align: center;
      img {
        width: 100%;
        height: 100%;
        // margin-right: 30px;
      }
    }
    p {
      text-align: center !important;
      margin: 5px 0 0 0;
    }
  }
}
</style>
