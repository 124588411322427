<template>
  <Modal :value="visible"
         :title="title"
         footer-hide
         width="800"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div>
      <Table ref="table"
             :columns="columnsList"
             :data="data"
             height="400"
             border
             stripe
             :loading="tableLoading">
        <template slot-scope="{ row }"
                  slot="operation">
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickEditAlarm(row)">修改</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickStatusWorkType(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>

        </template>
      </Table>
      <div class="modal-footer m-t-10">
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="mechanicalCleanWorkTypeVisible=true">
          <Icon custom="i-icon icon-tianjiabiankuang"
                size="16"></Icon>
          添加
        </Button>
      </div>
    </div>
    <!-- 修改按钮 -->
    <MechanicalCleanWorkTypeModal v-model="mechanicalCleanWorkTypeVisible"
                                  :dataId.sync="mechanicalCleanWorkTypeId"
                                  @onClickConfirm="getList"></MechanicalCleanWorkTypeModal>
  </Modal>
</template>

<script>
import MechanicalCleanWorkTypeModal from '@/components/product/admin/modal/work/MechanicalCleanWorkTypeModal'
export default {
  components: {
    MechanicalCleanWorkTypeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '作业类型'
    },
  },
  data () {
    return {
      data: [],
      parametDetailerType: '',
      columnsList: [
        {
          title: '作业类型',
          key: 'operationtype',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      // 修改参数弹框
      mechanicalCleanWorkTypeVisible: false,
      mechanicalCleanWorkTypeId: '',
    }
  },
  watch: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getList()
    },
    getList () {
      let params = {
        operationtype: "", //作业大类名称
        status: "",  //状态  0 停用 1 启用
        type: "car", //固定值类别 car 车辆  emp  人员
        page: this.pageNum, //页码
        size: this.pageSize  //条数
      }
      this.tableLoading = true
      this.$http.getWorkBigTypeList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.resultlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 作业大类修改
    onClickEditAlarm (row) {
      this.mechanicalCleanWorkTypeVisible = true
      this.mechanicalCleanWorkTypeId = row.id
    },
    // 修改作业大类状态
    onClickStatusWorkType (row) {
      if (row.status == '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditWorkBigType', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditWorkBigType', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
