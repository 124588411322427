<template>
  <Modal :value="visible"
         @on-visible-change="onChangeVisible"
         :title="title"
         width="600"
         class-name="vertical-center-modal">
    <MyTreeSelect :value="name"
                  placeholder="请选择人员"
                  readonly
                  clearable
                  style="width:100%"
                  :data="userTreeData"
                  search
                  @onSelectChange="onChangeName">
    </MyTreeSelect>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect';
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '绑定驾驶员'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      nameId: ''
    }
  },
  computed: {
    ...mapGetters({
      'userTreeData': 'getUserTreeList',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateUserTreeList',
    ]),
    init () {
      this.updateUserTreeList()
    },
    // 确认
    onClickConfirm () {
      this.loading = true
      let params = {
        ids: this.dataId,
        bindaccount: this.nameId
      }
      this.$store.dispatch('createOrEditCar', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      // !boo && this.$emit('onChange', false)
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
      this.nameId = ''
    },
    onChangeName (section) {
      if (section.type != 'depart') {
        this.name = section.name
        this.nameId = section.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
