<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="540"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-musat">*</span>作业名称：</p>
          <Input v-model="name"
                 placeholder="作业名称" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '作业类型'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '',
      remark: ''
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getWorkBigTypeDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
      })
    },
    // 点击确认
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入作业名称');
      this.loading = true
      let params = {
        name: this.name,
        remark: this.remark,
        type: 'car',
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditWorkBigType', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // 点击取消
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
