<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name+(item.number?'('+item.number+')':'')">
        </TabPane>
        <Button type="text"
                slot="extra"
                v-if="false"
                @click.stop="mechanicalCleanWorkTypeListVisible=true">设置作业类型>></Button>
      </Tabs>
      <div class="topbar-search"
           v-if="currentTabIndex===0||currentTabIndex===1||currentTabIndex===4">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Cascader :data="workBigTypeArr"
                      v-model="workBigType"
                      clearable
                      class="m-r-10"
                      style="width:160px"
                      change-on-select
                      placeholder="作业大类"></Cascader>
            <Select v-model="carType"
                    placeholder="车辆类型"
                    clearable
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in carTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <template v-if="currentTabIndex===0">
              <Select v-model="driveMode"
                      clearable
                      placeholder="驱动方式"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in driveModeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="operationStatus"
                      clearable
                      placeholder="运营状态"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in operationStatusArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="carOwnership"
                      clearable
                      placeholder="车辆所属权"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in carOwnershipArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.name"
                        :key="option.id">{{option.name}}</Option>
              </Select>

            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="driveMode"
                      clearable
                      placeholder="驱动方式"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in driveModeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="manufacturer"
                      clearable
                      placeholder="厂商"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in manufacturerArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===4">
              <Select v-model="remindType"
                      clearable
                      placeholder="提醒类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in remindTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Input v-model="carNumber"
                     placeholder="车牌号"
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/carArchives/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/carArchives/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <div class="flex">
          <template v-if="currentTabIndex===0">
            <div><Button type="primary"
                      class="m-r-10"
                      v-if="checkPermi(['/admin/carArchives/add'])"
                      @click.stop="carModalVisible=true">
                <Icon custom="i-icon icon-tianjia1"
                      size="16"></Icon>
                添加
              </Button>
            </div>
            <Dropdown v-if="checkPermi(['/admin/carArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            :type=1
                            v-if="checkPermi(['/admin/carArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        action='hss/tlcar/excelReader'
                        v-if="checkPermi(['/admin/carArchives/import'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/carArchives/edit','/admin/carArchives/delete'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <!-- <DropdownItem name="edit">批量修改</DropdownItem> -->
                <DropdownItem name="scrap"
                              v-if="checkPermi(['/admin/carArchives/edit'])">批量报废</DropdownItem>
                <DropdownItem name="recover"
                              v-if="checkPermi(['/admin/carArchives/edit'])">批量启用</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/carArchives/delete'])">批量删除</DropdownItem>
                <DropdownItem name="setAlarm"
                              v-if="checkPermi(['/admin/carArchives/edit'])">批量设置报警参数</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
          <template v-if="currentTabIndex===1">
            <div><Button type="primary"
                      class="m-r-10"
                      v-if="checkPermi(['/admin/carArchives/add'])"
                      @click.stop="carModelNumVisible = true">
                <Icon custom="i-icon icon-tianjia1"
                      size="16"></Icon>
                添加
              </Button>
            </div>
          </template>
          <template v-if="currentTabIndex===2">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/carArchives/add'])"
                    @click.stop="carTypeVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </template>
        </div>
        <template v-if="currentTabIndex===1">
          <div class="flex">
            <Button type="text"
                    @click.stop="onClickManufacturer">车辆厂商设置>></Button>
          </div>
        </template>
      </div>
      <template v-if="currentTabIndex===4">
        <Tabs v-model="currentTrafficTabIndex"
              class="tabs"
              key="child2">
          <TabPane v-for="item in trafficTabArr"
                   :key="item.name"
                   :label="item.name"></TabPane>
          <Button type="text"
                  class="m-l-5"
                  slot="extra">车务提醒设置>></Button>
        </Tabs>
      </template>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===0">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===0">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/carArchives/edit']"
                        @click.stop="onClickEditCar(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/carArchives/edit']"
                        @click.stop="onClickStatusCar(row)">{{row.status==1?'报废':'恢复'}}</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/carArchives/edit']"
                        @click.stop="onClickSetCar(row)">设置</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/carArchives/delete']"
                        @click.stop="onClickDeleteCar(row)">删除</Button>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/edit']"
                          @click.stop="onClickEditCarModel(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/edit']"
                          @click.stop="onClickStatusCarModel(row)">{{row.status==1?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/delete']"
                          @click.stop="onClickDeleteCarModel(row)"
                          @onChange='onChangClearRowStyle'>删除</Button>
                </template>
                <template v-if="currentTabIndex===2">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/edit']"
                          @click.stop="onClickEditCarType(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/edit']"
                          @click.stop="onClickStatusCarType(row)">{{row.status==1?'禁用':'恢复'}}</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/delete']"
                          @click.stop="onClickDeleteCarType(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex===3">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/edit']"
                          @click.stop="onClickEditAlarm(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/carArchives/edit']"
                          @click.stop="onClickStatusAlarm(row)">{{row.status==1?'禁用':'恢复'}}</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 新增车辆 -->
    <CarModal v-model="carModalVisible"
              :dataId.sync="carModalId"
              @onClickConfirm="getList"
              @onChange='onChangClearRowStyle'></CarModal>

    <!-- 新增车辆型号 -->
    <CarModelNumModal v-model="carModelNumVisible"
                      :dataId.sync="carModelNumDataId"
                      @onClickConfirm="getList"
                      @onChange='onChangClearRowStyle'></CarModelNumModal>

    <!-- 新增车辆类型 -->
    <CarTypeModal v-model="carTypeVisible"
                  :dataId.sync="carTypeDataId"
                  @onClickConfirm="getList"
                  @onChange='onChangClearRowStyle'></CarTypeModal>

    <!-- 车辆档案弹框 -->
    <CarDetailModal v-model="carDetailVisible"
                    :dataId.sync="carDetailId"
                    @onChange='onChangClearRowStyle'></CarDetailModal>

    <!-- 绑定对象 -->
    <BindEquipmentModal v-model="bindEquipmentVisible"
                        :item.sync="bindEquipmentObject"
                        @onClickConfirm="getList"
                        @onChange='onChangClearRowStyle'></BindEquipmentModal>

    <!-- 绑定驾驶员 -->
    <CarDriverBindingModal v-model="carDriverBindingVisible"
                           :dataId.sync="carDriverBindingDataId"
                           @onClickConfirm="getList"
                           @onChange='onChangClearRowStyle'></CarDriverBindingModal>

    <!-- 点击设置，修改车辆报警参数 -->
    <ParameterListModal :dataId.sync="carParameterDataId"
                        v-model="carParameterVisible"
                        thresholdType="car"
                        @onChange='onChangClearRowStyle'></ParameterListModal>

    <!-- 报警参数tab中的修改按钮 -->
    <ParameterModal v-model="carDetailParameterVisible"
                    :type="carDetailParameterType"
                    :dataId.sync="carDetailParameterDataId"
                    thresholdType="car"
                    @onClickConfirm="getList"
                    @onChange='onChangClearRowStyle'></ParameterModal>

    <!-- 车辆厂商 -->
    <CarManufacturerListModal v-model="carManufacturerVisible"></CarManufacturerListModal>

    <!-- 设置作业类型 -->
    <MechanicalCleanWorkTypeListModal v-model="mechanicalCleanWorkTypeListVisible"></MechanicalCleanWorkTypeListModal>

    <!-- 绑定围栏 -->
    <BindEnclosureModal v-model="bindEnclosureVisible"
                        :item.sync="bindEnclosureObject"
                        objectType="car"
                        @onClickConfirm="getList"
                        @onChange='onChangClearRowStyle'></BindEnclosureModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CarModal from '@/components/product/admin/modal/archives/CarModal.vue';
import CarModelNumModal from '@/components/product/admin/modal/archives/CarModelNumModal.vue';
import CarTypeModal from '@/components/product/admin/modal/archives/CarTypeModal.vue';
import CarDetailModal from '@/components/product/admin/modal/archives/CarDetailModal'
import BindEquipmentModal from '@/components/product/admin/modal/archives/BindEquipmentModal'
import BindEnclosureModal from '@/components/product/admin/modal/archives/BindEnclosureModal'
import CarDriverBindingModal from '@/components/product/admin/modal/archives/CarDriverBindingModal'
import ParameterListModal from '@/components/product/admin/modal/archives/ParameterListModal'
import ParameterModal from '@/components/product/admin/modal/archives/ParameterModal'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import MyTree from '@/components/common/MyTree';
import CarManufacturerListModal from '@/components/product/admin/modal/archives/CarManufacturerListModal'
import MechanicalCleanWorkTypeListModal from '@/components/product/admin/modal/work/MechanicalCleanWorkTypeListModal'
import ReaderFile from '@/components/common/ReaderFile'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
import { download } from '@/utils/utils'
export default {
  components: {
    MyTreeSelect,
    MyTree,
    CarModal,
    CarModelNumModal,
    CarTypeModal,
    CarDetailModal,
    BindEquipmentModal,
    BindEnclosureModal,
    CarDriverBindingModal,
    ParameterListModal,
    ParameterModal,
    CarManufacturerListModal,
    MechanicalCleanWorkTypeListModal,
    ReaderFile,
    ExportTemplate
  },
  data () {
    return {
      mechanicalCleanWorkTypeListVisible: false,
      currentTabIndex: 0,
      tabArr: [
        {
          name: '车辆列表',
          number: 0
        },
        {
          name: '车辆型号设置',
          number: 0
        },
        {
          name: '车辆类型设置',
          number: 0
        },
        {
          name: '报警参数设置',
          number: 0
        },
        // {
        //   name: '车务管理',
        //   number: 191
        // },
      ],
      // 车辆部门id
      cardeptid: '',
      // 作业大类
      workBigType: [],
      // 车辆类型
      carType: '',
      // carTypeArr: [],
      driveMode: '',
      driveModeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: '燃油',
          name: '燃油'
        },
        {
          id: '电动',
          name: '电动'
        },
        {
          id: '混合动力',
          name: '混合动力'
        },
      ],
      operationStatus: '',
      operationStatusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '正常'
        },
        {
          id: 2,
          name: '报废'
        },
      ],
      carOwnership: '',
      carOwnershipArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: '自用',
          name: '自用'
        },
        {
          id: '租用',
          name: '租用'
        },
        {
          id: '甲方',
          name: '甲方'
        }
      ],
      carNumber: '',
      carNumberId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      departmentName: '',
      departmentId: '',
      manufacturer: '',
      // manufacturerArr: [],
      remindType: -1,
      remindTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '保养提醒'
        },
        {
          id: 2,
          name: '保险提醒'
        },
        {
          id: 3,
          name: '年检提醒'
        },
      ],
      currentTrafficTabIndex: 0,
      trafficTabArr: [
        {
          name: '当前提醒'
        },
        {
          name: '提醒记录'
        },
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableWidths: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsList: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          width: 80,
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCarDetail(row)
                }
              }
            }, params.row.carnumber);
          }
        },
        {
          title: '所属机构',
          key: 'cardeptname',
          width: 180,
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业类型',
          key: 'carcategoryname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '运营状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('i', {
                style: {
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '2px',
                  background: row.status == 1 ? '#25bb96' : '#fa5357'
                }
              }),
              h('span', row.status == 1 ? '正常' : '报废')
            ]);
          }
        },
        {
          title: '车辆所属权',
          key: 'carattribution',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆型号',
          key: 'carmodelname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '驱动方式',
          key: 'drivemodel',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '图片',
          key: 'carpicture',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.carpicture) {
              let arr = row.carpicture.split(',').filter(item => item)
              if (arr.length) {
                return h('el-image', {
                  props: {
                    src: arr[0],
                    'preview-src-list': arr
                  },
                  style: {
                    width: '22px',
                    height: '22px',
                    cursor: 'pointer'
                  }
                })
              }
            }
            return h('span', '')
          }
        },
        {
          title: '驾驶员',
          key: 'bindaccount',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickDrive(row)
                }
              }
            }, row.bindaccount ? row.bindaccountname : '点击绑定');
          },
        },
        {
          title: '绑定设备数',
          key: 'bindfaclids',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEquipment(row)
                }
              }
            }, row.binds ? row.binds.length : '点击绑定');
          }
        },
        {
          title: '电子围栏',
          key: 'bindfaclids',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let arr = row.gridBindList.map(item => {
              return item.gridName
            })
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEnclosure(row)
                }
              }
            }, arr.length > 0 ? arr.join() : '点击绑定');
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsModel: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车辆型号',
          key: 'carmodel',
          minWidth: 60,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'cartypename',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '驱动方式',
          key: 'drivemodel',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '厂商',
          key: 'manufacturername',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '载重(T)',
          key: 'carload',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '油箱容积(L)',
          key: 'tankvolume',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '燃油类型',
          key: 'fueltype',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '电池容量(AH)',
          key: 'batterycapacity',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '百公里油耗值(L/100km)',
          key: 'oilmileage',
          width: 160,
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业宽度(m)',
          key: 'carweight',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsType: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车辆类型',
          key: 'typename',
          minWidth: 60,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '监测扫盘',
          key: 'monitorcleardish',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.status == 1 ? '是' : '否')
            ]);
          }
        },
        {
          title: '监测喷头',
          key: 'monitornozzle',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', [
              h('span', row.status == 1 ? '是' : '否')
            ]);
          }
        },
        {
          title: '转运类型',
          key: 'ransporttype',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          minWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsAlarm: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          width: 140,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      columnsTrafficCurrent: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'manageName',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '提醒类型',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '提醒日期',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '到期日期',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 80,
          align: 'center'
        }
      ],
      columnsTrafficList: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'manageName',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '提醒类型',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '提醒日期',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理日期',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '到期日期',
          key: 'managePhone',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 新增车辆弹框
      carModalVisible: false,
      carModalId: '',
      // 新增车辆型号弹框
      carModelNumVisible: false,
      carModelNumDataId: '',
      // 新增车辆类型弹框
      carTypeVisible: false,
      carTypeDataId: '',
      carTypeForm: {},
      // 新增车辆档案弹框
      carDetailVisible: false,
      carDetailId: null,
      // 修改车辆参数弹框
      carParameterVisible: false,
      carParameterDataId: '',
      // 绑定设备
      bindEquipmentVisible: false,
      bindEquipmentObject: null,
      // 绑定围栏
      bindEnclosureVisible: false,
      bindEnclosureObject: null,
      // 绑定驾驶员
      carDriverBindingVisible: false,
      carDriverBindingDataId: '',
      // 修改报警参数
      carDetailParameterVisible: false,
      carDetailParameterType: '',
      carDetailParameterDataId: '',
      // 厂商
      carManufacturerVisible: false,
      carManufacturerId: '',
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'carTreeArr': 'getCarTreeList',
      'carTypeArr': 'getCarTypeAllList',
    }),
    // 作业类型(级联)
    workBigTypeArr () {
      let arr = []
      arr = this.$store.getters.getCarWorkBigClassAllList.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.itemlist
        item.children.map(subItem => {
          subItem.value = subItem.id
          subItem.label = subItem.name
          return subItem
        })
        return item
      })
      return arr
    },
    // 厂商
    manufacturerArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarManufacturerAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsList
          break;
        case 1:
          columns = this.columnsModel
          break;
        case 2:
          columns = this.columnsType
          break;
        case 3:
          columns = this.columnsAlarm
          break;
        case 4:
          switch (this.currentTrafficTabIndex) {
            case 0:
              columns = this.columnsTrafficCurrent
              break;
            case 1:
              columns = this.columnsTrafficList
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCar
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportCarTem
          break;
        default:
          break;
      }
      return path
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarTreeList',
      'updateCarWorkBigClassAllList',
      'updateCarManufacturerAllList',
      'updateCarTypeAllList',
      'updateCarTypeEnableAllList'
    ]),
    init () {
      this.tableWidths = window.innerWidth - 240
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 360
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 240
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 360
      }
      this.updateDepartmentTreeList()
      this.updateCarTreeList()
      this.updateCarWorkBigClassAllList()
      this.updateCarManufacturerAllList()
      this.updateCarTypeAllList()
      this.updateCarTypeEnableAllList()
      this.getList()
    },
    onClickTreeItem (id) {
      this.currentTreeId = id
    },
    onChangeDate (date) {
      this.date = date
    },
    // 批量操作下拉菜单
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'delete':
          console.log('delete');
          this.onDeleteBatchCar(this.selectArr)
          break;
        // 报废
        case 'scrap':
          this.$Modal.confirm({
            title: '报废',
            content: '确认报废?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 0
              }
              this.$store.dispatch('createOrEditCar', params).then(res => {
                this.getList()
                // 更新车辆数下拉框
                this.$store.dispatch('updateCarTreeList', true)
              }).catch(err => {
                this.$Message.info(err.message)
              })
            }
          })
          break;
        // 恢复
        case 'recover':
          this.$Modal.confirm({
            title: '启用',
            content: '确认启用?',
            onOk: () => {
              let params = {
                ids: this.selectArr.join(),
                status: 1
              }
              this.$store.dispatch('createOrEditCar', params).then(res => {
                this.getList()
                // 更新人员树列表
                this.$store.dispatch('updatePersonTreeList', true)
              }).catch(err => {
                this.$Message.info(err.message)
              })
            }
          })
          break;
        case 'setAlarm':
          this.carParameterDataId = this.selectArr.join()
          this.carParameterVisible = true
          break;
        default:
          break;
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 修改车牌号123
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      // this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // tab多选
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    // 排序
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.cardeptid = data.id
      this.onClickSearch()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getCarList()
          break;
        case 1:
          this.getCarModelNumList()
          break;
        case 2:
          this.getCarTypeList()
          break;
        case 3:
          this.getAlarmList()
          break;
        case 4:
          switch (this.currentTrafficTabIndex) {
            case 0:
              break;
            case 1:
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // 车辆列表
    getCarList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carCategory: this.workBigType[0] ? this.workBigType[0] : '',
        carCategoryItem: this.workBigType[1] ? this.workBigType[1] : '',
        carTypeId: this.carType,
        carAttribution: this.carOwnership == 0 ? '' : this.carOwnership,
        // carNumber: this.carNumber,
        driveModel: this.driveMode == 0 ? '' : this.driveMode,
        status: this.operationStatus == 1 ? 1 : this.operationStatus == 2 ? 0 : '',

        carDeptId: this.departmentId,
        carNumber: this.remoteCar ? this.remoteCar.split('[')[0] : '',  // 车牌号    
        // 是否绑定电子围栏
        // bindingStatus: '',
      }
      this.tableLoading = true
      this.$http.getCarList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击车辆详情
    onClickCarDetail (row) {
      this.carDetailVisible = true
      this.carDetailId = row.id
      this.remoteCar = row.carId
      this.selectVisitedArr.push(row)
      // this.$store.dispatch('getCarDetail', row.id).then(res => {
      //   this.carDetailData = res
      //   this.carDetailVisible = true
      // })
    },
    // 点击绑定设备
    onClickBindEquipment (row) {
      row.type = 'car'
      row.item = row.cartypeid
      this.bindEquipmentObject = row
      this.bindEquipmentVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击绑定电子围栏
    onClickBindEnclosure (row) {
      this.bindEnclosureObject = row
      this.bindEnclosureVisible = true
      this.selectVisitedArr.push(row)
    },
    // 修改车辆
    onClickEditCar (row) {
      this.selectVisitedArr.push(row)
      this.carModalId = row.id
      this.carModalVisible = true
    },
    // 修改车辆状态
    onClickStatusCar (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '报废',
          content: '确认报废?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditCar', params).then(res => {
              this.getList()
              // 更新车辆数下拉框
              this.$store.dispatch('updateCarTreeList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              ids: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditCar', params).then(res => {
              this.getList()
              // 更新车辆数下拉框
              this.$store.dispatch('updateCarTreeList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 设置车辆报警状态
    onClickSetCar (row) {
      this.carParameterDataId = row.id
      this.carParameterVisible = true
      this.selectVisitedArr.push(row)
    },
    // 表格点击删除车辆
    onClickDeleteCar (row) {
      this.onDeleteBatchCar([row.id])
      this.selectVisitedArr.push(row)
    },
    // 删除车辆
    onDeleteBatchCar (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchCar(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.getList()
              // 更新车辆数下拉框
              this.$store.dispatch('updateCarTreeList', true)
            }
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 绑定驾驶员
    onClickDrive (row) {
      if (row.bindaccount) {
        this.$Modal.confirm({
          title: '解绑',
          content: '确认解绑?',
          onOk: () => {
            let params = {
              ids: row.id,
              bindaccount: ''
            }
            this.$store.dispatch('createOrEditCar', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.carDriverBindingDataId = row.id
        this.carDriverBindingVisible = true
        this.selectVisitedArr.push(row)
      }
    },
    // 关闭 添加驾驶员弹窗
    onChangeCarDriverBinding () {
      this.carDriverBindingDataId = ''
    },
    // 车辆型号列表
    getCarModelNumList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        operationtypeid: this.workBigType[0] ? this.workBigType[0] : '',
        cartypeid: this.carType,
        carmodel: '',
        manufacturer: this.manufacturer == 0 ? '' : this.manufacturer,
        drivemodel: this.driveMode == 0 ? '' : this.driveMode,
        status: ''
      }
      this.tableLoading = true
      this.$http.getCarModelNumList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.resultlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆型号修改
    onClickEditCarModel (row) {
      this.carModelNumDataId = row.id
      this.carModelNumVisible = true
      this.selectVisitedArr.push(row)
    },
    // 车辆型号删除
    onClickDeleteCarModel (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteCarModelNum(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
              // 更新车辆类型下拉列表
              this.$store.dispatch('updateCarTypeEnableAllList', true)
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改车辆型号状态
    onClickStatusCarModel (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditCarModelNum', params).then(res => {
              this.getList()
              // 更新车辆类型下拉列表
              this.$store.dispatch('updateCarTypeEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditCarModelNum', params).then(res => {
              this.getList()
              // 更新车辆类型下拉列表
              this.$store.dispatch('updateCarTypeEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 车辆类型列表
    getCarTypeList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        operationtypeid: '',
        typename: '',
        status: ''
      }
      this.tableLoading = true
      this.$http.getCarTypeList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.resultlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆类型修改
    onClickEditCarType (row) {
      this.carTypeDataId = row.id
      this.carTypeVisible = true
      this.selectVisitedArr.push(row)
    },
    // 车辆类型删除
    onClickDeleteCarType (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteCarType(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
              // 更新车辆类型下拉列表
              this.$store.dispatch('updateCarTypeEnableAllList', true)
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改车辆类型状态
    onClickStatusCarType (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('createOrEditCarType', params).then(res => {
              this.getList()
              // 更新车辆类型下拉列表
              this.$store.dispatch('updateCarTypeEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('createOrEditCarType', params).then(res => {
              this.getList()
              // 更新车辆类型下拉列表
              this.$store.dispatch('updateCarTypeEnableAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 获取报警列表
    getAlarmList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        thresholdtype: 'car'
      }
      this.tableLoading = true
      this.$http.getAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.thresholdlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 报警参数修改
    onClickEditAlarm (row) {
      this.selectVisitedArr.push(row)
      this.carDetailParameterVisible = true
      this.carDetailParameterType = row.sysname
      this.carDetailParameterDataId = row.id
    },
    // 修改报警参数状态
    onClickStatusAlarm (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              status: 0,
              thresholdtype: 'car',
              name: row.alarmname,
              sysname: row.sysname,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              status: 1,
              thresholdtype: 'car',
              name: row.alarmname,
              sysname: row.sysname,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    // 厂商设置
    onClickManufacturer () {
      this.carManufacturerVisible = true
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.selectArr = []
      this.workBigType = []
      this.departmentId = ''
      this.departmentName = ''
      this.carType = ''
      this.carOwnership = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.remoteCar = ''
      this.driveMode = ''
      this.operationStatus = ''
      this.cardeptid = ''
      this.manufacturer = ''
    },
    onChangClearRowStyle (e) {
      console.log(e);
      this.selectVisitedArr = []

    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            carcategory: this.workBigType[0] ? this.workBigType[0] : '',
            carcategoryitem: this.workBigType[1] ? this.workBigType[1] : '',
            cartypeid: this.carType,
            carattribution: this.carOwnership == 0 ? '' : this.carOwnership,
            carnumber: this.carNumber,
            drivemodel: this.driveMode == 0 ? '' : this.driveMode,
            status: this.operationStatus == 1 ? 1 : this.operationStatus == 2 ? 0 : '',
            cardeptid: this.cardeptid,
          }
          if (value == 'now') {
            data.ids = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.ids = this.selectArr
          } else {
            // data.ids = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;

      .tree-title {
        background: #f5f5f5;
        font-size: 14px;
        color: #25bb96;
        padding: 15px 0 15px 12px;
        font-weight: bold;
      }

      .tree-list {
        .tree-item {
          padding: 0 8px;
          line-height: 36px;
          cursor: pointer;

          &:hover {
            background: #f0f3f7;
          }

          &.active {
            background: #f0f3f7;
          }
        }
      }
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
