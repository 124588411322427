/**
 * 防抖
 * 频繁操作防止抖动，在操作后 n 秒内不操作，才触发事件，若继续操作，则重新计时
 * @param {function} fn 回调方法
 * @param {Number} delay 时间（毫秒）
 * @param {Boolean} immediate 是否开始立即执行
 */
export const debounce = (fn, delay = 500, immediate = false) => {
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate && !timer) {
      fn.apply(this, args)
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
}

/**
 * 节流
 * 频繁操作稀释函数执行，频繁操作时，每隔n秒才触发一次
 * @param {function} fn 回调方法
 * @param {Number} t 时间（毫秒）
 */
export const throttle = (fn, t) => {
  let last;
  let timer;
  let interval = t || 500;
  return function () {
    let args = arguments;
    let now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
}