<template>
  <Drawer :title="title"
          closable
          width="45"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="detail"
         v-if="data">
      <div class="detail-top">
        <div class="detail-top-title">
          <Icon custom="i-icon icon-che"
                size="32"
                color="#ffffff"
                class="detail-top-title-icon" />
          <div class="detail-top-title-div">
            <p>{{data.plateNumber || "--"}}</p>
            <div><span>所属部门：</span>{{data.departmentTree || "--"}}</div>
          </div>
        </div>
        <div class="detail-top-msg">
          <p>驾驶员：<span>{{data.bindaccountname || "--"}}</span></p>
          <p>驱动方式：<span>{{data.drivemodel || "--"}}</span></p>
          <p>车龄：<span>{{data.day || "--"}}</span></p>
        </div>
        <div>
          <el-image :src="data.carPicture"
                    fit="cover"
                    :preview-src-list="[data.carPicture]"
                    class="detail-top-img"
                    v-if="data.carPicture">
          </el-image>
          <span v-else>暂无图片</span>
        </div>
      </div>
      <Tabs v-model="detailTabIndex">
        <TabPane label="基础概况"></TabPane>
        <TabPane label="车辆参数"></TabPane>
      </Tabs>
      <div v-show="detailTabIndex == 0">
        <div class="section">
          <div class="section-title">
            <span>基础信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>车辆型号:</label>
              <span>{{data.carmodelname || "--"}}</span>
            </li>
            <li>
              <label>报废日期:</label>
              <span>{{data.scrapTime || "--"}}</span>
            </li>
            <li>
              <label>车辆所属权:</label>
              <span>{{data.ownership || "--"}}</span>
            </li>
            <li>
              <label>发动机号:</label>
              <span>{{data.engine || "--"}}</span>
            </li>
            <li>
              <label>车架号:</label>
              <span>{{data.carframe || "--"}}</span>
            </li>
            <li>
              <label>购入日期:</label>
              <span>{{data.time || "--"}}</span>
            </li>
            <li>
              <label>购入金额:</label>
              <span>{{data.money || "--"}}</span>
            </li>
            <li>
              <label>上牌日期:</label>
              <span>{{data.careTime || "--"}}</span>
            </li>
            <li>
              <label>初始里程:</label>
              <span>{{data.mileage || "--"}}</span>
            </li>
            <li>
              <label>监测喷头:</label>
              <span>{{data.sprayer ? (data.sprayer==0 ? '否' : '是') : "--"}}</span>
            </li>
            <li>
              <label>监测扫盘:</label>
              <span>{{data.sweepPlate ? (data.sweepPlate==0 ? '否' : '是') : "--"}}</span>
            </li>
            <li>
              <label>转运类型:</label>
              <span>{{data.transferType || "--"}}</span>
            </li>
            <li>
              <label>油卡编号:</label>
              <span>{{data.oilCard || "--"}}</span>
            </li>
            <li>
              <label>油箱容积:</label>
              <span>{{data.detail.fuelTank || "--"}}</span>
            </li>
            <li>
              <label>油箱校正值:</label>
              <span>{{data.fuelTankCorrectValues || "--"}}</span>
            </li>
            <li>
              <label>燃油类型:</label>
              <span>{{data.detail.fuelOil || "--"}}</span>
            </li>
            <li>
              <label>百公里油耗:</label>
              <span>{{data.detail.fuelConsumption || "--"}}</span>
            </li>
            <li>
              <label>厂商:</label>
              <span>{{data.detail.firm || "--"}}</span>
            </li>
            <li>
              <label>车身颜色:</label>
              <span>{{data.carColor || "--"}}</span>
            </li>
            <li>
              <label>车辆净重:</label>
              <span>{{data.detail.suttle || "--"}}</span>
            </li>
            <li>
              <label>载重:</label>
              <span>{{data.load || "--"}}</span>
            </li>
            <li>
              <label>作业宽度:</label>
              <span>{{data.detail.workwidth || "--"}}</span>
            </li>
            <li class="one-row">
              <label>曾用车牌号:</label>
              <span>{{data.usedLicense || "--"}}</span>
            </li>
            <li class="one-row">
              <label>备注:</label>
              <span>{{data.remark || "--"}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>车务信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>下次年检:</label>
              <span>{{data.annualInspection || "--"}}</span>
            </li>
            <li>
              <label>保险到期:</label>
              <span>{{data.insurance || "--"}}</span>
            </li>
            <li>
              <label>下次保养日期:</label>
              <span>{{data.maintainDate || "--"}}</span>
            </li>
            <li>
              <label>下次保养里程:</label>
              <span>{{data.maintainMileage || "--"}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>行驶证信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>行驶证号:</label>
              <span>{{data.drivingLicenseNumber || "--"}}</span>
            </li>
            <li>
              <label>发证日期:</label>
              <span>{{data.drivingLicenseDate || "--"}}</span>
            </li>
            <li class="img-row">
              <label>行驶证照:</label>
              <el-image :src="data.licensemainpicture"
                        fit="cover"
                        :preview-src-list="[data.licensemainpicture]"
                        v-if="data.licensemainpicture"
                        class="m-r-10">
              </el-image>
              <span v-else>暂无行驶证正面图片</span>
              <el-image :src="data.licenseotherpicture"
                        fit="cover"
                        :preview-src-list="[data.licenseotherpicture]"
                        v-if="data.licenseotherpicture">
              </el-image>
              <span v-else>暂无行驶证反面图片</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>绑定设备</span>
          </div>
          <div>
            <Table ref="equipmentTable"
                   :columns="columnsEquipmentList"
                   :data="tableEquipmentData"
                   border
                   stripe
                   :loading="tableEquipmentLoading">
            </Table>
            <!-- <div class="noData">
              <img src="../../../../../assets/common/biaogeno.png">
              <p>暂无数据</p>
            </div> -->
          </div>
        </div>
      </div>

      <div v-show="detailTabIndex == 1">
        <div class="section">
          <div class="section-title">
            <span>报警参数</span>
          </div>
          <Table ref="alarmTable"
                 :columns="columnsParameterList"
                 :data="tableParameterData"
                 border
                 stripe
                 :loading="tableParameterLoading">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button type="text"
                      class="tableOperaBtn"
                      @click.stop="onClickEditAlarm(row)">修改</Button>
              <Button type="text"
                      class="tableOperaBtn"
                      @click.stop="onClickStatusAlarm(row)">{{row.status==='启用'?'禁用':'恢复'}}</Button>

            </template>
          </Table>
        </div>
      </div>
    </div>

    <!-- 车辆报警参数的修改按钮 -->
    <ParameterModal v-model="carDetailParameterVisible"
                    :type="carDetailParameterType"
                    :dataId.sync="carDetailParameterId"
                    thresholdType="car"
                    @onClickConfirm="getList"></ParameterModal>
  </Drawer>
</template>

<script>
import ParameterModal from '@/components/product/admin/modal/archives/ParameterModal'
export default {
  components: {
    ParameterModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '车辆档案'
    },
    dataId: String
  },
  data () {
    return {
      data: {
        detail: {}
      },
      detailTabIndex: 0,
      columnsEquipmentList: [
        {
          title: '设备编号',
          key: 'equipcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypeid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备通讯号',
          key: 'communicationcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '厂商',
          key: 'manufacturerid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        }
      ],
      tableEquipmentData: [],
      tableEquipmentLoading: false,
      columnsParameterList: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          mainWidth: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == '启用') {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      tableParameterData: [],
      tableParameterLoading: false,
      totalPage: 0,
      pageNum: 1,
      pageSize: 99999,
      // 修改参数弹框
      carDetailParameterVisible: false,
      carDetailParameterId: '',
      carDetailParameterType: ''
    };
  },
  watch: {
    dataId (newValue) {
      if (newValue) {
        this.getDetail()
        this.getEquipmentList()
      }
    },
    detailTabIndex (newValue) {
      if (newValue == 1) {
        this.getList()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    // 车辆详情
    getDetail () {
      this.$store.dispatch('getCarDetail', this.dataId).then(res => {
        this.data = res
      })
    },
    // 报警参数列表
    getList () {
      let params = {
        itemid: this.data.id,
        page: this.pageNum,
        size: this.pageSize,
        // thresholdtype: 'car'
      }
      this.tableParameterLoading = true
      this.$http.getAlarmList(params).then(res => {
        this.tableParameterLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.tableParameterData = res.result.thresholdlist
        }
      }).catch(err => {
        this.tableParameterLoading = false
      })
    },
    // 报警参数修改
    onClickEditAlarm (row) {
      this.carDetailParameterVisible = true
      this.carDetailParameterType = row.sysname
      this.carDetailParameterId = row.id
    },
    // 修改报警参数状态
    onClickStatusAlarm (row) {
      if (row.status == '启用') {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0,
              thresholdtype: 'car'
            }
            this.$store.dispatch('createOrEditAlarm', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1,
              thresholdtype: 'car'
            }
            this.$store.dispatch('createOrEditAlarm', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    getEquipmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        bindtype: 'car',
        bindobjid: this.dataId,
      }
      this.$http.getEquipmentList(params).then(res => {
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.tableEquipmentData = res.result.equiplist
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
        this.detailTabIndex = 0
      }
    },
    resetData () {
      this.data = {
        detail: {}
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.detail {
  .detail-top {
    padding: 8px 27px 0;
    .detail-top-title {
      display: flex;
      margin-bottom: 10px;
      .detail-top-title-icon {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #0079fe;
        text-align: center;
        line-height: 50px;
        margin-right: 7px;
      }
      .detail-top-title-div {
        color: #333;
        font-size: 14px;
        p {
          font-size: 18px;
        }
        div {
          color: #6a6b75;
        }
      }
    }
    .detail-top-msg {
      display: flex;
      font-size: 13px;
      color: #333;
      margin-bottom: 4px;
      span {
        color: #0079fe;
        padding-right: 45px;
      }
    }
    .detail-top-img {
      width: 170px;
      height: 108px;
    }
  }
  .section {
    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;

      span:first-child {
        font-weight: 700;
        // font-size: 16px;
        padding-left: 10px;
        border-left: 2px solid #25bb96;
      }
    }
    .section-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 15px;
      li {
        width: 50%;
        line-height: 34px;
        label {
          display: inline-block;
          min-width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
      .one-row {
        width: 100%;
      }
      .img-row {
        display: flex;
        align-items: flex-start;
        img {
          width: 50%;
          margin-right: 10px;
        }
        span {
          margin-right: 10px;
        }
      }
    }
    .noData {
      p {
        color: #000;
      }
    }
  }
}
</style>